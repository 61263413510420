import React from "react";
import qs from "querystring";

const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY;

/**
 * The OrderPaymentWizard handles completing an order, beginning with a raw order payload
 * (i.e. with subitems, etc), and finally ending up with client-side payment confirmation.
 *
 * This component is designed to be reused on both the watch site and frontend site. It is
 * implemented as a class-based component for maximum backwards compatibility.
 *
 * The following props are required:
 *   orderData: { order: {subitems}, event_bucket, token ... }
 *   apiRequest: ({method, url, data, etc...}) => Promise<Response{data}>
 *   renderSubmitButton: ({ disabled, isSubmittingOrder }) => <button type="submit" />
 *     Note: MUST be type="submit"! Otherwise submit event will not fire.
 *   onOrderWaitingForCompletion: (data: CheckoutCompletionData) => void
 *   onOrderCompleted: (order) => void
 *   onOrderCanceled: () => void
 *
 * The implementing application must implement logic such that if _complete_checkout is found in
 * the window querystring, <OrderPaymentWizard/> is rendered.
 *
 * Optional prop, if passed in, will force into state of waiting for checkout to finish:
 *   waitingForCheckoutCompletion: CheckoutCompletionData
 *
 * To catch login/token expired errors and show a re-auth screen:
 *    onLoginExpired: () => void
 */
class OrderPaymentWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hardErrorMessage: "",
      softErrorMessage: "",

      isBootstrapping: true,
      bootstrapData: null,

      useSavedPaymentMethod: false,

      isPreparingServerOrder: false,
      serverOrder: null,

      isStripeElementLoaded: false,

      isPurchasing: false,

      // When waiting for a completed order
      isCompletingCheckout: false
    };

    this.onOrderFormSubmit = this.onOrderFormSubmit.bind(this);
  }

  componentDidMount() {
    if (!window.evStripe) {
      window.evStripe = window.Stripe(STRIPE_PUBLIC_KEY, {
        betas: []
      });
    }
    this.stripe = window.evStripe;

    if (
      this._hasCompletingOrderQueryString() ||
      this.props.waitingForCheckoutCompletion
    ) {
      // Attempt to finalize payment if needed
      this.checkForCompletingOrder();
    } else {
      // Kickoff initial bootstrap
      this.bootstrap();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Once we've bootstrapped, prepare the initial order.
    if (
      this.state.bootstrapData &&
      !this.state.isPreparingServerOrder &&
      !this.state.serverOrder
    ) {
      this.prepareOrder();
      return;
    }

    // Once bootstrapped, if there is a change in the order payload, prepare the order again.
    if (
      this.state.bootstrapData &&
      !_isSame(this.props.orderData, prevProps.orderData)
    ) {
      this.prepareOrder();
    }
  }

  componentWillUnmount() {
    if (this._hasCompletingOrderQueryString()) {
      this._clearQueryString();
    }
  }

  _hasCompletingOrderQueryString() {
    const query = qs.parse(window.location.search.substring(1));
    return !!query._complete_checkout;
  }

  checkForCompletingOrder() {
    const query = qs.parse(window.location.search.substring(1));

    if (this.props.waitingForCheckoutCompletion) {
      query._complete_checkout = true;
      query.payment_intent =
        this.props.waitingForCheckoutCompletion.payment_intent;
    }

    if (query._complete_checkout) {
      if (query.redirect_status === "failed") {
        this.setState({
          hardErrorMessage: "The payment failed. Please try again."
        });
        return;
      }

      this.setState(
        {
          isCompletingCheckout: true,
          serverOrder: {
            id: query.payment_intent
          }
        },
        () => {
          this.pollForCompletedOrder();
        }
      );
    }
  }

  async bootstrap() {
    const { orderData, apiRequest } = this.props;

    if (!orderData.token) {
      this.setState({
        hardErrorMessage: `Your login has expired. Please sign out, then try again. (token missing)`
      });
      if (this.props.onLoginExpired) {
        this.props.onLoginExpired();
      }
      return;
    }

    let bootstrapData;
    try {
      bootstrapData = await apiRequest({
        method: "POST",
        url: `orders/setup_payment_wizard`,
        data: { token: orderData.token }
      });
    } catch (e) {
      this.setState({
        hardErrorMessage: `Failed to load payment portal. Please refresh the page and try again. (${e.message})`
      });
      if (e?.code === "InvalidCredentials" && this.props.onLoginExpired) {
        this.props.onLoginExpired();
      }
      return;
    }

    this.setState({
      isBootstrapping: false,
      bootstrapData: bootstrapData.data,
      useSavedPaymentMethod: !!bootstrapData.data.saved_payment_method
    });
  }

  async prepareOrder() {
    // Force running `prepareOrder` process serially. This prevents race conditions
    // where we might end up dispatching two /orders/prepare calls, but the first one
    // ends up finishing after the second one, resulting in the wrong amount set on the
    // payment intent.
    // For example:
    //  User : Clicks $10 donation
    //  A : POST /orders/prepare { amount: 10 }
    //  A : Waiting...
    //  User: Clicks $5 donation
    //  B : POST /orders/prepare { amount: 5 }
    //  B : Success { amount: 5 }
    //  A : Success { amount: 10 }
    //  UI : Shows $5 donation as this was last clicked by user
    //  Server : State of PaymentIntent is $10 since this was last finished
    // By ensuring we fire prepareOrder serially, we avoid this race by ensuring that we
    // have received a response from the server before dispatching another request.
    if (this.state.isPreparingServerOrder) {
      if (!this._waitingToPrepareInterval) {
        this._waitingToPrepareInterval = setInterval(() => {
          this.prepareOrder();
        }, 100);
      }
      return;
    }
    clearInterval(this._waitingToPrepareInterval);
    this._waitingToPrepareInterval = null;

    const { orderData, apiRequest } = this.props;

    this.setState({ isPreparingServerOrder: true, hardErrorMessage: "" });

    const payment_intent = this.state.serverOrder
      ? this.state.serverOrder.id
      : null;

    let serverOrder;
    try {
      serverOrder = await apiRequest({
        method: "POST",
        url: `orders/prepare`,
        data: { ...orderData, payment_intent }
      });
    } catch (e) {
      this.setState({
        hardErrorMessage: e.message
      });
      return;
    }

    let newServerOrder = serverOrder.data;

    // In the case where an order "loses" its payment intent (due to no longer requiring payment),
    // go ahead and save the payment intent in the order in case they later switch it back the
    // other way. This way we minimize how many extra payment intents we have to create.
    if (payment_intent && this.state.serverOrder && !newServerOrder.id) {
      newServerOrder.id = this.state.serverOrder.id;
      newServerOrder.secret = this.state.serverOrder.secret;
      newServerOrder.status = this.state.serverOrder.status;
    }

    this.setState(
      {
        isPreparingServerOrder: false,
        hardErrorMessage: "",
        softErrorMessage: "",
        serverOrder: newServerOrder
      },
      () => {
        let clientSecret;
        if (newServerOrder.secret?.indexOf("pi_demo") === 0) {
          // In demo mode, extract the demoable client secret so that we can still render
          // the checkout experience. pi_demo_client_secret$$pi_xxx_secret_yyy
          clientSecret = newServerOrder.secret.split("$$")[1];
        } else {
          clientSecret = newServerOrder.secret;
        }

        // If necessary, now that we have an order ready, initialize the Stripe elements
        // instance as well so that we can collect the payment itself.
        if (clientSecret) {
          if (!this.elements) {
            this.elements = this.stripe.elements({
              clientSecret,
              appearance: PAYMENT_ELEMENT_APPEARANCE
            });
            this.paymentElement = this.elements.create("payment", {
              business: {
                name: "Eventive"
              },
              fields: {
                billingDetails: {
                  name: "never",
                  email: "never"
                }
              },
              terms: {
                card: "never"
              }
            });
            this.paymentElement.on("ready", () => {
              this.setState({ isStripeElementLoaded: true });
            });
            this.paymentElement.mount(this.elementDiv);
          } else {
            this.elements.fetchUpdates();
          }
        }
      }
    );
  }

  async onOrderFormSubmit(event) {
    // Block native form submission.
    event.preventDefault();
    const { apiRequest, onOrderCompleted } = this.props;
    const {
      bootstrapData,
      isPreparingServerOrder,
      serverOrder,
      useSavedPaymentMethod
    } = this.state;
    const { stripe, elements } = this;

    if (isPreparingServerOrder) {
      // The order is not yet ready.
      return;
    }

    this.setState({ isSubmittingOrder: true, softErrorMessage: "" });

    const hasQueryString = window.location.href.indexOf("?") !== -1;
    const return_url = `${window.location.href}${
      hasQueryString ? "&" : "?"
    }_complete_checkout=1`;

    // If no payment required, just submit order immediately and complete.
    // Same for demo orders, where we don't actually push these through Stripe.
    const isDemo = serverOrder.id?.indexOf("pi_demo") === 0;
    if (serverOrder.no_payment_required || isDemo) {
      try {
        const r = await apiRequest({
          method: "POST",
          url: `orders`,
          data: {
            version: "2",
            ...this.props.orderData,
            ...(isDemo ? { payment_intent: serverOrder.id } : null)
          }
        });
        onOrderCompleted(r.data.order);
      } catch (e) {
        this.setState({ hardErrorMessage: e.message });
      }
      return;
    }

    // Otherwise, go through Stripe async payment flow.
    let result;
    try {
      if (useSavedPaymentMethod) {
        result = await stripe.confirmCardPayment(serverOrder.secret, {
          payment_method: bootstrapData.saved_payment_method.id
        });
      } else {
        result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url,
            payment_method_data: {
              billing_details: {
                name: bootstrapData.name,
                email: bootstrapData.email
              }
            }
          },
          redirect: "if_required"
        });
      }
    } catch (e) {
      console.error(e);
      result = { error: e };
    }

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      this.setState({
        softErrorMessage: result.error.message,
        isSubmittingOrder: false
      });
    } else if (result.paymentIntent) {
      // Redirect was not required, so complete the checkout inline.
      this.props.onOrderWaitingForCompletion({
        payment_intent: serverOrder.id
      });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  async pollForCompletedOrder() {
    this.setState({ isCompletingCheckout: true });

    let tokenAttempts = 0;
    let order = null;

    while (!order) {
      if (this.props.orderData.token) {
        try {
          const r = await this.props.apiRequest({
            method: "POST",
            url: `orders`,
            data: {
              version: "2",
              payment_intent: this.state.serverOrder.id,
              token: this.props.orderData.token
            }
          });
          if (r.data.status === "error") {
            this.setState({
              hardErrorMessage: `Order failed: ${r.data.error_message}`
            });
            return;
          } else if (r.data.status === "processed") {
            order = r.data.order;
          }
        } catch (e) {
          this.setState({ hardErrorMessage: e.message });
          return;
        }
      } else {
        tokenAttempts++;
        if (tokenAttempts > 2) {
          // If we have tried more than twice and still not gotten the user's token,
          // they are probably just logged out, so assume we're done.
          this._clearQueryString();
          this.props.onOrderCanceled();
        }
      }

      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    this._clearQueryString();
    this.props.onOrderCompleted(order);
  }

  _clearQueryString() {
    const paramsToRemove = [
      "_complete_checkout",
      "payment_intent",
      "payment_intent_client_secret",
      "redirect_status"
    ];

    // Delayed to ensure any other page navigation events don't race condition with us.
    setTimeout(() => {
      const url = new URL(window.location.href);

      const params = url.searchParams;

      // Loop through the parameters and delete those in the list to remove
      for (let param of params.keys()) {
        if (paramsToRemove.includes(param)) params.delete(param);
      }

      const newUrl =
        params.size < 1 ? url.pathname : url.pathname + "?" + params.toString();

      // Update the URL without reloading the page
      window.history.replaceState({}, "", newUrl);
    }, 500);
  }

  render() {
    const {
      hardErrorMessage,
      softErrorMessage,

      isBootstrapping,
      bootstrapData,

      useSavedPaymentMethod,

      isPreparingServerOrder,
      serverOrder,

      isStripeElementLoaded,

      isSubmittingOrder,

      isCompletingCheckout
    } = this.state;

    const submitButtonDisabled =
      isBootstrapping ||
      isPreparingServerOrder ||
      isSubmittingOrder ||
      (!serverOrder?.no_payment_required &&
        !isStripeElementLoaded &&
        !useSavedPaymentMethod);

    if (hardErrorMessage) {
      return (
        <div style={{ color: "rgb(223, 27, 65)", textAlign: "center" }}>
          {hardErrorMessage}
          <div style={{ marginTop: "0.75em" }}>
            <button
              className="font-medium text-sm focus:outline-none hover:opacity-75 border px-2 py-0.5 rounded"
              style={{
                borderColor: "rgb(223, 27, 65)"
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </button>
          </div>
        </div>
      );
    }

    if (isCompletingCheckout) {
      return (
        <div style={{ padding: 24 }}>
          <Loader
            style={{
              color: "#252f3f",
              margin: "1.5em auto"
            }}
          />
          <p style={{ textAlign: "center", fontSize: "0.875em" }}>
            Completing your order...
          </p>
        </div>
      );
    }

    return (
      <form
        className="Eventive--OrderPaymentWizard"
        onSubmit={this.onOrderFormSubmit}
      >
        {serverOrder ? (
          <>
            {serverOrder.no_payment_required ? null : bootstrapData?.saved_payment_method ? (
              <div className="text-sm text-always-gray-500">
                {useSavedPaymentMethod ? (
                  <div className="flex items-center justify-center -mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                      <path
                        fillRule="evenodd"
                        d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-2 leading-none">
                      {bootstrapData.saved_payment_method.label} &middot;{" "}
                      <a
                        href="#"
                        className="hover:underline cursor-pointer hover:text-always-gray-600"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ useSavedPaymentMethod: false });
                        }}
                      >
                        change
                      </a>
                    </span>
                  </div>
                ) : (
                  <div className="mb-4">
                    <span className="font-medium">
                      Adding a new payment method
                    </span>{" "}
                    &middot;{" "}
                    <a
                      href="#"
                      className="hover:underline cursor-pointer hover:text-always-gray-600"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ useSavedPaymentMethod: true });
                      }}
                    >
                      undo
                    </a>
                  </div>
                )}
              </div>
            ) : null}
            <div
              style={
                serverOrder.no_payment_required || useSavedPaymentMethod
                  ? { display: "none" }
                  : {}
              }
              ref={r => {
                this.elementDiv = r;
              }}
            />
          </>
        ) : (
          <Loader
            style={{
              color: "#252f3f",
              margin: "1.5em auto"
            }}
          />
        )}
        {softErrorMessage ? (
          <div
            style={{
              marginTop: "1.5em",
              color: "rgb(223, 27, 65)",
              fontSize: "0.875em"
            }}
          >
            {softErrorMessage}
          </div>
        ) : null}
        <div style={{ marginTop: "1.5em" }}>
          {this.props.renderSubmitButton
            ? this.props.renderSubmitButton({
                disabled: submitButtonDisabled,
                isWorking: serverOrder && submitButtonDisabled,
                isSubmittingOrder
              })
            : undefined}
        </div>
      </form>
    );
  }
}

function _isSame(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const PAYMENT_ELEMENT_APPEARANCE = {
  theme: "none",
  rowGap: "15px",
  variables: {
    fontSizeBase: "14px",
    fontFamily:
      'Inter var, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontLineHeight: "1.5"
  },
  rules: {
    ".Tab": {
      border: "none",
      borderRadius: "6px",
      padding: "10px 12px 8px 12px",
      color: "#727F96",
      boxShadow: "0 0 0 1px #e0e0e0",
      backgroundColor: "#ffffff",
      transition: "box-shadow 0.08s ease-in, border 0.08s ease-in"
    },
    ".Tab:focus": {
      outline: "none",
      boxShadow:
        "0 0 0 1px rgba(50, 151, 211, .3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3)"
    },
    ".Tab:hover": {
      border: "none",
      color: "#727F96",
      boxShadow:
        "0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05)"
    },
    ".Tab--selected, .Tab--selected:hover": {
      border: "none",
      boxShadow:
        "0 0 0 1.5px #0570DE, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05)"
    },
    ".Tab--selected:focus": {
      boxShadow:
        "0 0 0 1.5px #0570DE, 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3)"
    },
    ".TabIcon--selected": {
      fill: "#0570DE"
    },
    ".TabLabel--selected": {
      color: "#0570DE"
    },
    ".Input": {
      border: "none",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.25",
      padding: "10px",
      borderRadius: "6px",
      boxShadow:
        "0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05)"
    },
    ".Input:focus": {
      outline: "none",
      boxShadow:
        "0 0 0 1px rgb(180, 198, 252), 0 1px 1px 0 rgb(180, 198, 252), 0 0 0 3px rgba(180, 198, 252, 0.45)"
    },
    ".Input::placeholder": {
      color: "#A3ACB9"
    },
    ".Label": {
      color: "#252f3f",
      fontWeight: "500",
      fontSize: "14px"
    },
    ".Text": {
      color: "#252f3f",
      fontWeight: "500"
    },
    ".Text--redirect": {
      paddingTop: "10px",
      fontSize: "0.875em",
      color: "#0570DE"
    }
  }
};

const Loader = ({ className, style }) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    stroke="currentColor"
    className={className}
    style={style}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);

export default OrderPaymentWizard;
