import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import SupplementaryDataField from "./SupplementaryDataField";
import Button from "./Button";
import Loader from "./Loader";

const makeInitialData = ({ user, fields }) => {
  const obj = user?.details?.supplementary_data || {};
  const splitIndex = user?.details?.name?.indexOf(" ");
  const firstNameGuess =
    splitIndex === -1
      ? user?.details?.name
      : user?.details?.name?.substring(0, splitIndex);
  const lastNameGuess =
    splitIndex === -1 ? "" : user?.details?.name?.substring(splitIndex + 1);

  for (const field of fields) {
    if (typeof obj[field.field] !== "undefined") {
      continue;
    }

    if (field.type === "string") {
      if (field.guess === "first_name") {
        obj[field.field] = firstNameGuess;
      } else if (field.guess === "last_name") {
        obj[field.field] = lastNameGuess;
      } else {
        obj[field.field] = "";
      }
    }
  }

  return obj;
};

export default function CheckoutSupplementaryData({ onSuccess }) {
  const { user, token, tenant, update, api } = useAuth();
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const fields = tenant?.virtual_festival_supplementary_data?.map(f => {
    if (!f.key && f.field) {
      f.key = f.field;
    }
    return f;
  });

  useEffect(() => {
    api.get(`people/self?token=${token}`).then(res => {
      setData(makeInitialData({ user: res.data, fields }));
    });
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    setIsWorking(true);
    setError(null);
    update({ supplementary_data: data })
      .then(() => {
        setError(null);
        onSuccess();
      })
      .catch(e => {
        setError(e.message);
        setIsWorking(false);
      });
  };

  if (!fields) {
    return null;
  }

  if (!data) {
    return (
      <div className="mt-6 mb-2">
        <Loader className="transform scale-150 text-always-gray-500 mx-auto" />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="text-always-gray-600 text-sm">
        {tenant?.virtual_festival_supplementary_data_introduction}
      </div>
      <form onSubmit={onSubmit} className="-mx-2">
        <SupplementaryDataField fields={fields} data={data} setData={setData} />

        <div className="mt-6 text-center">
          <Button type="submit" disabled={isWorking}>
            {isWorking ? "Saving..." : "Continue"}
          </Button>
        </div>
        {error ? (
          <p className="mt-2 text-sm text-red-600 text-center">{error}</p>
        ) : null}
      </form>
    </div>
  );
}
