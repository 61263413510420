import React from "react";
import moment from "moment";
import { formatBrowserTimezone, formatInterval } from "../util";

moment.relativeTimeThreshold("h", 25);

const InlineAccent = ({ children }) => (
  <span className="inline-flex whitespace-no-wrap items-center px-2 sm:px-3 sm:py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-700 text-gray-100 no-translate">
    {children}
  </span>
);

export default function AvailabilityInformation({
  event,
  numberOfFilms,
  isInFuture,
  numberOfLivestreams,
  featuredLivestream,
  unlockInterval,
  isAarp,
  language
}) {
  if (language) {
    moment.locale(language);
  }
  let now = new Date().getTime();
  let unlockEnd = now + unlockInterval;
  let start_time = new Date(event.start_time).getTime();
  if (now < start_time) {
    unlockEnd = start_time + unlockInterval;
  }
  let endTime = new Date(event.end_time).getTime();
  let availUntil = new Date(Math.min(unlockEnd, endTime)).getTime();

  const shared = {
    isBeforeStart: now < start_time,
    start: moment(event.start_time).format("MMMM Do [at] h:mm a"),
    end: moment(event.end_time).format("MMMM Do [at] h:mm a"),
    endTimeOnly: moment(event.end_time).format("h:mm a"),
    availUntil: moment(availUntil).format("MMMM Do [at] h:mm a"),
    livestreamStart: featuredLivestream
      ? moment(featuredLivestream.start_time).format("MMMM Do [at] h:mm a")
      : "",
    timezone: formatBrowserTimezone(event.start_time),
    unlockInterval: formatInterval(unlockInterval || 0),
    watchInterval: formatInterval(event.watch_interval || 0),
    numberOfFilms
  };

  // Omit misleading instructions about external instructions livestream
  if (featuredLivestream?.livestream_mode === "external") {
    numberOfLivestreams = 0;
  }

  let key;
  if (event.realtime_broadcast) {
    key = event.realtime_broadcast_become_vod_afterwards
      ? "realtime-broadcast-become-vod-afterwards"
      : isAarp 
      ? "aarp-realtime-broadcast" 
      : "realtime-broadcast";
  } else {
    key = isInFuture ? "intro-future" : "intro-present";
    key += numberOfFilms ? "-film" : "-nofilm";
    key += numberOfLivestreams ? "-livestream" : "-nolivestream";
    if (numberOfLivestreams) {
      key += event.end_time ? "-endtime" : "-noendtime";
    }
  }

  const info = {
    "realtime-broadcast-become-vod-afterwards": (
      <>
        This{" "}
        {numberOfFilms > 1 ? (
          <>
            program of <var>{numberOfFilms}</var> films
          </>
        ) : (
          "program"
        )}{" "}
        {shared.isBeforeStart ? "will" : "was"} broadcast live <var>{shared.start + " " + shared.timezone}</var>.{" "}
        {shared.isBeforeStart ? "You will not be able to pause or rewind. " : " "} 
        {shared.isBeforeStart ? "After the live broadcast, you can access " : "You can now unlock and access "} 
        an on-demand recording any time until <var>{shared.availUntil + " " + shared.timezone}</var>.{" "}
        Once you begin watching, you will have <InlineAccent>{shared.watchInterval}</InlineAccent>{" "}
        to complete your viewing.{" "}
      </>
    ),
    "realtime-broadcast": (
      <>
        {`This ${numberOfFilms > 1 ? (
          `program of ${numberOfFilms} films`
        ) : (
          "program"
        )} will broadcast live ${shared.start} to ${shared.endTimeOnly} ${shared.timezone}.  You will not be able to pause or rewind. You cannot access the show once the broadcast ends.`}
      </>
    ),
    "aarp-realtime-broadcast": (
      <>
        <font style={{ fontWeight: "bold" }}>To register for this event, log in to your{" "} 
        AARP.org account</font> or create one. This program will broadcast{" "}
        <font style={{ fontWeight: "bold" }}> live <var>{shared.start}</var>{" "}
        to {shared.endTimeOnly + " " + shared.timezone}.</font>{" "}
        You will not be able to pause or rewind. You <font style={{ fontWeight: "bold" }}>cannot</font>{" "}
        access the show once the broadcast ends.
      </>
    ),
    "intro-future-film-livestream-endtime": (
      <>
        After this content becomes available{" "}
        <var>{shared.start + " " + shared.timezone}</var>, you'll have{" "}
        <InlineAccent>{shared.unlockInterval}</InlineAccent> to start watching.{" "}
        Once you begin, you'll have{" "}
        <InlineAccent>{shared.watchInterval}</InlineAccent> to finish watching{" "}
        the {numberOfFilms > 1 ? "films" : "film"}. The{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed anytime until <var>{shared.end}</var>.
      </>
    ),
    "intro-future-film-livestream-noendtime": (
      <>
        After this content becomes available{" "}
        <var>{shared.start + " " + shared.timezone}</var>, you'll have{" "}
        <InlineAccent>{shared.unlockInterval}</InlineAccent> to start watching.{" "}
        Once you begin, you'll have{" "}
        <InlineAccent>{shared.watchInterval}</InlineAccent> to finish watching
        the {numberOfFilms > 1 ? "films" : "film"}. The{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed at any time.
      </>
    ),
    "intro-future-nofilm-livestream-endtime": (
      <>
        After this content becomes available{" "}
        <var>{shared.start + " " + shared.timezone}</var>, the{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed anytime until <var>{shared.end}</var>.
      </>
    ),
    "intro-future-nofilm-livestream-noendtime": (
      <>
        After this content becomes available{" "}
        <var>{shared.start + " " + shared.timezone}</var>, the{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed at any time.
      </>
    ),
    "intro-future-film-nolivestream": (
      <>
        After this content becomes available{" "}
        <var>{shared.start + " " + shared.timezone}</var>, you'll have{" "}
        <InlineAccent>{shared.unlockInterval}</InlineAccent> to start watching.{" "}
        Once you begin, you'll have{" "}
        <InlineAccent>{shared.watchInterval}</InlineAccent> to finish watching.
      </>
    ),

    "intro-present-film-livestream-endtime": (
      <>
        After unlocking, you'll have{" "}
        <InlineAccent>{shared.unlockInterval}</InlineAccent> to start watching.{" "}
        Once you begin, you'll have{" "}
        <InlineAccent>{shared.watchInterval}</InlineAccent> to finish watching{" "}
        the {numberOfFilms > 1 ? "films" : "film"}. The{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed anytime until <var>{shared.end}</var>.
      </>
    ),
    "intro-present-film-livestream-noendtime": (
      <>
        After unlocking, you'll have{" "}
        <InlineAccent>{shared.unlockInterval}</InlineAccent> to start watching.{" "}
        Once you begin, you'll have{" "}
        <InlineAccent>{shared.watchInterval}</InlineAccent> to finish watching{" "}
        the {numberOfFilms > 1 ? "films" : "film"}. The{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed at any time.
      </>
    ),
    "intro-present-nofilm-livestream-endtime": (
      <>
        After unlocking, the{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed anytime until {shared.end}.
      </>
    ),
    "intro-present-nofilm-livestream-noendtime": (
      <>
        After unlocking, the{" "}
        <var>{shared.livestreamStart + " " + shared.timezone}</var> livestream
        can be viewed at any time.
      </>
    ),
    "intro-present-film-nolivestream": (
      <>
        After unlocking, you'll have{" "}
        <InlineAccent>{shared.unlockInterval}</InlineAccent> to start watching.{" "}
        Once you begin, you'll have{" "}
        <InlineAccent>{shared.watchInterval}</InlineAccent> to finish watching.
      </>
    ),
    "intro-present-nofilm-nolivestream": (
      <>
        After unlocking, you will be provided instructions to access this{" "}
        content externally.
      </>
    ),
    "intro-future-nofilm-nolivestream": (
      <>
        After unlocking, you will be provided instructions to access this{" "}
        content externally.
      </>
    )
  };

  return info[key];
}
