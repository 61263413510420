import React from "react";
import classNames from "classnames";

export default function SupplementaryDataField({ fields, data, setData }) {
  if (!fields || !fields.length > 0) {
    return null;
  }

  return fields.map(field => {
    return (
      <div
        className={classNames("mt-6 mx-2")}
        style={
          field.style === "half"
            ? { width: "calc(50% - 1rem)", display: "inline-block" }
            : {}
        }
      >
        {field.type !== "checkbox" ? (
          <label
            htmlFor={field.key}
            className="block text-sm font-medium leading-5 text-always-gray-700"
          >
            {field.label}
          </label>
        ) : null}
        {field.type === "string" ? (
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              name={field.key}
              type="string"
              required
              className="form-input block w-full text-sm sm:leading-5"
              value={data[field.key]}
              onChange={e => {
                let value = e.target.value;
                if (field.validator === "postal_code") {
                  value = value.toUpperCase();
                }
                setData({ ...data, [field.key]: value });
              }}
            />
          </div>
        ) : field.type === "boolean" ? (
          <div className="mt-2 leading-none">
            <label class="inline-flex items-center">
              <input
                name={field.key}
                type="radio"
                class="form-radio h-5 w-5"
                checked={data[field.key] === true}
                required
                onChange={e =>
                  setData({ ...data, [field.key]: e.target.checked })
                }
              />
              <span class="ml-2 text-always-gray-700 font-medium text-sm">
                Yes
              </span>
            </label>
            <label class="inline-flex items-center ml-4">
              <input
                name={field.field}
                type="radio"
                class="form-radio h-5 w-5"
                checked={data[field.key] === false}
                required
                onChange={e =>
                  setData({ ...data, [field.key]: !e.target.checked })
                }
              />
              <span class="ml-2 text-always-gray-700 font-medium text-sm">
                No
              </span>
            </label>
          </div>
        ) : field.type === "select" ? (
          <div className="mt-1">
            <select
              name={field.key}
              className="block w-full pl-2 pr-10 py-2 border border-always-gray-300 text-sm rounded-md"
              onChange={e => setData({ ...data, [field.key]: e.target.value })}
              value={data[field.key] || ""}
              required
            >
              <option disabled={true} value="">
                Select an option
              </option>
              {field.options.map(option => (
                <option>{option}</option>
              ))}
            </select>
          </div>
        ) : field.type === "multiselect" ? (
          <div className="mt-2">
            {field.options.map(option => {
              return (
                <label className="mt-1 text-always-gray-600 text-left text-sm flex justify-center">
                  <input
                    className="mr-2 form-checkbox"
                    type="checkbox"
                    style={{ marginTop: "0.2rem" }}
                    checked={(data[field.key] || []).indexOf(option) !== -1}
                    onChange={e => {
                      setData({
                        ...data,
                        [field.key]: (data[field.key] || [])
                          .filter(v => v !== option)
                          .concat(e.target.checked ? [option] : [])
                      });
                    }}
                  />
                  <span className="flex-1">{option}</span>
                </label>
              );
            })}
          </div>
        ) : field.type === "checkbox" ? (
          <label className="mt-1 text-always-gray-600 text-left text-sm flex justify-center">
            <input
              className="mr-2 form-checkbox"
              type="checkbox"
              required
              style={{ marginTop: "0.2rem" }}
              checked={data[field.key]}
              onChange={e => {
                setData({
                  ...data,
                  [field.key]: e.target.checked
                });
              }}
            />
            <span className="flex-1">{field.label}</span>
          </label>
        ) : null}
      </div>
    );
  });
}
