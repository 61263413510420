import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import SupplementaryDataField from "./SupplementaryDataField";
import Button from "./Button";
import Loader from "./Loader";

export default function CheckoutPassSupplementaryData({
  pass,
  isDataUpdate,
  isEdit,
  editData,
  onSuccess
}) {
  const { tenant, user } = useAuth();
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  let fields = [{ type: "string", key: "name", label: "Passholder name" }];
  fields = fields.concat(
    (isDataUpdate
      ? pass?.pass_bucket?.supplementary_data
      : pass?.supplementary_data) || []
  );

  const allowedFields = [
    "string",
    "boolean",
    "select",
    "multiselect",
    "checkbox"
  ];
  fields = fields.reduce((arr, field) => {
    if (allowedFields.includes(field.type)) {
      arr.push(field);
    }
    return arr;
  }, []);

  useEffect(() => {
    if (isDataUpdate || isEdit) {
      let newData = {};
      for (let f = 0; f < fields.length; f++) {
        const fieldKey = fields[f].key;
        if (fieldKey === "name") {
          newData.name = isDataUpdate ? pass.name : editData.name[0];
        } else {
          if (isDataUpdate) {
            newData[fieldKey] = pass?.supplementary_data[fieldKey];
          } else {
            newData[fieldKey] = editData.supplementary_data.find(obj => {
              if (Object.keys(obj).includes(fieldKey)) return true;
            })[fieldKey];
          }
        }
      }
      setData(newData);
    } else if (user) {
      setData({ name: user.details.name });
    }
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    setIsWorking(true);
    setError(null);
    onSuccess(data);
  };

  if (!fields) {
    return null;
  }

  return (
    <div className="mt-4">
      <div className="text-always-gray-600 text-sm text-left">
        {isDataUpdate
          ? `Update your ${tenant.display_pass} details below.`
          : `To order your ${tenant.display_pass}, please provide the following details.`}
      </div>
      <form onSubmit={onSubmit} className="-mx-2">
        <SupplementaryDataField fields={fields} data={data} setData={setData} />
        <div className="mt-6 text-center">
          <Button type="submit" disabled={isWorking}>
            {isWorking ? "Saving..." : "Continue"}
          </Button>
        </div>
        {error ? (
          <p className="mt-2 text-sm text-red-600 text-center">{error}</p>
        ) : null}
      </form>
    </div>
  );
}
